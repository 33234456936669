





























































import Vue from 'vue'
import globalConfig from '@/global-config'
import AuthService from '@/services/auth'
import UserService from '@/services/user'
import { UserLoginReq } from '@/services/auth/models'
import StorageKeys from '@/constants/storage-keys'
import { FormRef, Rule } from '@/models/form'

import * as RecaptchaV3 from 'recaptcha-v3'

export default Vue.extend({
  data(): {
    dataReq: UserLoginReq
    rules: Record<string, Rule[]>
    isLoading: boolean
    verifyOtpModalOpen: boolean
    otp?: string
  } {
    return {
      dataReq: {
        email: '',
        password: '',
        isTrusted: false
      },
      isLoading: false,
      rules: {
        email: [
          { required: true, message: 'Please input email address', trigger: ['blur', 'change'] },
          { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
        ],
        password: [{ required: true, message: 'Please input your password', trigger: ['blur', 'change'] }]
      },
      verifyOtpModalOpen: false,
      otp: undefined
    }
  },

  methods: {
    async captcha() {
      try {
        const recaptcha = await RecaptchaV3.load(globalConfig.VUE_APP_CAPTCHA_SITEKEY)

        const token = await recaptcha.execute('submit')

        return token
      } catch (err) {
        console.error('[captcha] error', err)
      }
    },
    async login() {
      this.isLoading = true
      try {
        const captchaToken = await this.captcha()

        const { data: loginResData } = await AuthService.login({
          ...this.dataReq,
          captchaToken
        })
        // store auth profile
        localStorage.setItem(StorageKeys.authProfile, loginResData.data)

        // check device verify
        const { data: deviceVerify } = await UserService.getDeviceVerify()

        if (deviceVerify.data) {
          this.$router.push({ name: 'Dashboard' })
        } else {
          this.verifyOtpModalOpen = true
        }
      } finally {
        this.isLoading = false
      }
    },

    async submitForm(formName: string) {
      const formRef = this.$refs[formName] as FormRef

      formRef.validate((valid: boolean) => {
        if (valid) {
          this.login()
        } else {
          return false
        }
      })
    },

    async verifyOtp() {
      if (!this.otp) return

      this.isLoading = true
      try {
        const { data: verifyOtpRes } = await UserService.verifyDevice({ otp: this.otp })
        if (verifyOtpRes.success) {
          this.$router.push({ name: 'Dashboard' })
        }
      } finally {
        this.isLoading = false
      }
    }
  }
})
